<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Dashboard"
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
       <div class="col-xl-4">
            <div class="card bg-primary">
                <a href="/customer/add" class="btn btn-success waves-effect waves-light">Add a New Customer</a>
            </div>
        </div>
    </div>
    <div class="row">
       <div class="col-xl-4">
            <div class="card bg-primary">
              <a href="/setting/add-car-brand" class="btn btn-success waves-effect waves-light">Add a New Car Brand</a>
            </div>
        </div>
    </div>
  </Layout>
</template>